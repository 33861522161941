/* eslint-disable */
chartData = undefined
layoutStr = undefined
loadingCmpltChartCnt = 0
chartReadyHandler = function (id) {
  document.getElementById(id).setLayout(layoutStr)
  document.getElementById(id).setData(chartData)
  // console.log("====CHARTDATA====")
  // console.log(chartData)
  // console.log(layoutStr)
  // chartData.length = 0
  // if(chartData.length == 0) document.getElementById(id).hasNoData(true)
}

//
displayCompleteFunc = function(id) {
  loadingCmpltChartCnt++
  if (loadingCmpltChartCnt === 3) { // 가족, 생활, 의료 3개의 방사형 차트 완료시 페이지 이동
    // route의 screenNumber가 가입설계에서 온 값일 경우는 이동하지 않는다는 조건이 해당 함수에 있음
    window.vue.getInstance('vue').$bizUtil.ctUtils.fn_moveTSSCT004M()
    loadingCmpltChartCnt = 0
  }
}

/******************************************************************************
  * TSSCT010M 계약현황분석_보장기간
******************************************************************************/
// y축 상품명에 스타일적용 및 타사일 경우 추가 스타일 적용
fn_TSSCT010M_vAxisLabelFunc = function (id, text, data, val) {
  //console.log("fn_TSSCT010M_vAxisLabelFunc", id, text, data, val)
  let chartData = document.getElementById("CT010M").getChartData(),
      prdtNm = '',
      rtnVal = '',
      prefixPrdtNm = ''
      prefixPrdtNmColor = ''
  for (let i in chartData) {
    if(chartData[i].contNo == text){
      if(!chartData[i].tcomYn){
        if (chartData[i].gtplOtcomPrdtInptScCd === '3') {
          prefixPrdtNm = '(스)'
          prefixPrdtNmColor = '#e09609'
        } else if (chartData[i].gtplOtcomPrdtInptScCd === '4') {
          prefixPrdtNm = '(신)'
          prefixPrdtNmColor = '#830ff9'
        } else {
          prefixPrdtNm = '(타)'
          prefixPrdtNmColor = '#7d8aa8'
        }
        prefixPrdtNm = '<font size="24" color="' + prefixPrdtNmColor + '">' + prefixPrdtNm + '</font>'
        rtnVal = prefixPrdtNm + '<font size="24">' + window.vue.getInstance('vue').$bizUtil.omitStr(chartData[i].prdtNm, 8) + '</font>'
      }
      else {
        prdtNm = chartData[i].prdtNm
        rtnVal = '<font size="24">' + window.vue.getInstance('vue').$bizUtil.omitStr(prdtNm, 11) + '</font>'
      }
      break
    }
  }
  return rtnVal
}

fn_TSSCT010M_hAxisLabelFunc = function (id, text) {
  let rtnVal = '',
      chartData = document.getElementById("CT010M").getChartData(),
      ageList = [chartData[0].custCurAge,
                chartData[0].custCurAge + 10,
                chartData[0].custCurAge + 20,
                chartData[0].custCurAge + 30,
                chartData[0].custCurAge + 40,
                chartData[0].wlifeAge
      ]
  for(let i in ageList) {
    if(ageList[i] == text) {
      rtnVal = text
      break;
    }
  }

  // rMateChart가 간헐적으로 x축의 마지막값에서는 해당 함수를 타지 않는 버그로 인해 112세 or 113세에 '종신' 문구 노출
  // if(chartData[0].wlifeAge == text) rtnVal = '종신'
  if(chartData[0].wlifeAge == Number(text) || chartData[0].wlifeAge == Number(text)+1) rtnVal = '종신'

  //console.log("fn_TSSCT010M_hAxisLabelFunc", id, text)
  return '<font size="30">' + rtnVal + '</font>'
}

// seriesId : 레이아웃에서 부여한 시리즈의 식별자(Identifier)가 있을 경우 해당 식별자를 나타냅니다.
// seriesName : 시리즈의 displayName 속성값으로 지정한 시리즈 이름을 나타냅니다.
// xName : X축에 displayName 을 정의하였다면 X축의 displayName 을 나타냅니다.
// yName : Y축에 displayName 을 정의하였다면 Y축의 displayName 을 나타냅니다.
// data : 해당 아이템의 값을 표현하기 위해 입력된 데이터. (데이터의 종류에 따라서 XML의 내용 또는 배열이 됩니다.)
// values : 해당 아이템의 값입니다. 배열로 전달되며 차트의 종류에 따라 아래와 같습니다.
//          바 시리즈(Bar2DSeries, Bar3DSeries) 0 : x축 값, 1 : y축 값
fn_TSSCT010M_dataTip = function (seriesId, seriesName, index, xName, yName, data, values) {
  //console.log(data)
  let payPeriod = (data.padCmpltAge - data.custCurAge < 0 ? '납입완료' : (data.padCmpltAge - data.custCurAge) + '년') // 납입기간
  let covanPeriod = (data.expAge - data.custCurAge < 0 ? 0 : data.expAge - data.custCurAge)  + "년" // 보장기간

  return "<font size='3' color='#CC3300'>상품명 : \n " + data.prdtNm + "</font>"
  +"<br/><font size='3' color='#0000FF'>납입기간 : \n " + payPeriod  + "</font>"
  +"<br/><font size='3' color='#0000FF'>보장기간 : \n " + (data.expAge === 113 ? '종신' : covanPeriod) + "</font>";
}

fn_TSSCT010M_axisClickFunc = function (id, text) {
  let chartData = document.getElementById("CT010M").getChartData()
  for (let i in chartData) {
    if(chartData[i].contNo == text && !chartData[i].tcomYn){
      console.log('chartData[i]', chartData[i])
      // console.log('gtplOtcomPrdtInptScCd :: ' + chartData[i].gtplOtcomPrdtInptScCd + ', contNo :: ' + text)
      window.vue.getInstance('vue').$bizUtil.ctUtils.fn_extFnOpenPopup(chartData[i].gtplOtcomPrdtInptScCd, chartData[i].chnlCustId, text);
    }
  }
}

fn_TSSCT010M_fillJsFunction = function (seriesId, index, data, values) {

  if (seriesId === 'padCmplt'){
    return {'color':'#58b7ff', 'alpha':1.0}
  } else if (seriesId === 'expTerm') {
    return {'color':'#58b7ff', 'alpha':0.2}
  }
}

fn_TSSCT010M_displayCompleteFunc = function(id) {
  let chartData = document.getElementById("CT010M").getChartData()
  // 딜레이 시간 = 차트 상품 건수 * 0.05초 (최소 1초)
  let delay = chartData.length * 50
  delay = delay < 1000 ? 1000 : delay

  setTimeout( function () {    
    window.vue.getStore('progress').dispatch('UPDATE', false) // 차트 생성후 로딩바 지우기    
  }, delay)
}

fn_TSSCT005M_dataTip = function (seriesId, seriesName, index, xName, yName, data, values) {
  // console.log('1:: ', seriesId, seriesName)
  // console.log('2:: ', index, xName, yName)
  // console.log('3:: ', data, values)
  // let fChartData = document.getElementById("ct0005m0").getChartData() // 가족
  // let lChartData = document.getElementById("ct0005m1").getChartData() // 생활
  // let hChartData = document.getElementById("ct0005m2").getChartData() // 의료
  let currChartData = []
  let retVal = ''
  // 현재 어느 차트인지를 구분
  for (let i = 0; i < 3; i++) {
    let chartData = document.getElementById('ct0005m'+i).getChartData() // 0가족, 1생활, 2의료
    for (let idx in chartData) {
      if (data.covrName === chartData[idx].covrName) {
        currChartData = chartData
        break
      }
    }
  }
  // console.log('currChartData :: ', currChartData)

  if (values[0] === '0') {
    // 0 포인트를 클릭했을 경우 해당 차트의 값이 0원인 급부명이 모두 툴팁에 노출
    console.log('00')
    retVal += "<table cellpadding='0' cellspacing='1'>"
    retVal += "<tr><td><font size='3'>급부명</font></td><td><font size='3'>&nbsp보장자산&nbsp</font></td><td><font size='3'>&nbsp표준모델</font></td></tr>"
    currChartData.forEach( function(item, idx, arry) {
      if (item.covrRate === 0) {
        retVal += "<tr>"
        retVal += "<td align='left'><font size='2'>"+item.covrName+"</font></td>"
        retVal += "<td align='right'><font size='2'>"+window.vue.getInstance('vue').$commonUtil.numberWithCommas(item.covrAmt)+"</font></td>"
        retVal += "<td align='right'><font size='2'>"+window.vue.getInstance('vue').$commonUtil.numberWithCommas(item.requireAmt)+"</font></td>"
        retVal += "</tr>"
      }
    })
    retVal += "</table>"
  } else {
    // 0원이 아닌 값 눌렀을때는 하나만 노출
    retVal += "<table cellpadding='0' cellspacing='1'>"
    retVal += "<tr><td>"
    retVal += "<font size='3' face='맑은 고딕'><b>" + data.covrName + "</b></font>"
    retVal += "</td><tr>"
    retVal += "<tr><td align='left'>"
    retVal += "<font size='2'>보장자산 : </font>" + "<font size='2'face='맑은 고딕'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.covrAmt) + "</font>" + "<font size='2'> 만원 </font>" // 보장금액
    retVal += "</td><tr>"
    retVal += "<tr><td align='left'>"
    retVal += "<font size='2'>표준모델 : </font>" + "<font size='2'face='맑은 고딕'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.requireAmt) + "</font>"  + "<font size='2'> 만원 </font>" // 표준모델
    retVal += "</td><tr>"
    retVal += "</table>"
  }
  return retVal
}

fn_TSSCT005M_LabelFunc = function (id, value) {
  // console.log('value ::: '+ value)
  let covrInfo = value.split('|')
  let covrName = covrInfo[0] // 보장급부명
  let overAmt = covrInfo[1]  // 과부족금액 ( 총보장자산 - 표준모델 )
  // console.log('covrName ::: '+ covrName)
  // console.log('overAmt ::: '+ Number(overAmt))

  retVal = "<font size='12'>" + covrName + "</font>"
  if ( Number(overAmt) < 0 ) {
    retVal += "<font size='12' color='#CC3300'>" + '▼' + "</font>"
  }
  // console.log('retVal ::: '+ retVal)
  return retVal
}

/******************************************************************************
  * TSSCT009M 보장분석 : 설계후_전후비교
******************************************************************************/
fn_TSSCT009M_displayCompleteFunc = function(id) { // 적용예정
  loadingCmpltChartCnt++
  if (loadingCmpltChartCnt === 3) { // 가족, 생활, 의료 3개의 방사형 차트 완료시 페이지 이동
    loadingCmpltChartCnt = 0
    setTimeout( function () {
      window.vue.getStore('progress').dispatch('UPDATE', false)  // 차트 생성후 로딩바 지우기
    }, 500)  
  }
}

fn_TSSCT009M_LabelFunc = function (id, value) {
  let covrInfo = value.split('|')
  let covrName = covrInfo[0] // 보장급부명
  let overAmt = covrInfo[1]  // 과부족금액 ( 총보장자산 - 표준모델 )
  // console.log('covrName ::: '+ covrName)
  // console.log('overAmt ::: '+ Number(overAmt))

  retVal = "<font size='12'>" + covrName + "</font>"
  if ( Number(overAmt) < 0 ) {
    retVal += "<font size='12' color='#CC3300'>" + '▼' + "</font>"
  }
  // console.log('retVal ::: '+ retVal)
  return retVal
}

fn_TSSCT009M_dataTip = function (seriesId, seriesName, index, xName, yName, data, values) {
  // jskim 20181210
  let currChartData = []
  let retVal = ''
  let pChartId = ['famChartView', 'lvngChartView', 'mdcrChartView']
  // 현재 어느 차트인지를 구분
  for (let i = 0; i < 3; i++) {
    let chartData = document.getElementById('ct0009m'+pChartId[i]).getChartData() // 0가족, 1생활, 2의료
    for (let idx in chartData) {
      if (data.covrName === chartData[idx].covrName) {
        currChartData = chartData
        break
      }
    }
  }

  // console.log('currChartData :: ', currChartData)
  if (values[0] === '0') {
    // console.log('00')
    retVal += "<table cellpadding='0' cellspacing='1'>"
    retVal += "<tr><td><font size='5'>급부</font></td><td><font size='5'>&nbsp전&nbsp</font></td><td><font size='5'>&nbsp후&nbsp</font></td><td><font size='5'>&nbsp표준모델</font></td></tr>"
    currChartData.forEach( function(item, idx, arry) {
      if (item.covrAfRate === 0 && item.covrBfRate === 0) {
        retVal += "<tr>"
        retVal += "<td align='left'><font size='4'>"+item.covrName+"</font></td>"
        // retVal += "<td align='right'><font size='4'>"+window.vue.getInstance('vue').$commonUtil.numberWithCommas(item.covrAmt)+"</font></td>"
        retVal += "<td align='center'><font size='4'>"+window.vue.getInstance('vue').$commonUtil.numberWithCommas(item.covrBfAmt)+"</font></td>"
        retVal += "<td align='center'><font size='4'>"+window.vue.getInstance('vue').$commonUtil.numberWithCommas(item.covrAfAmt)+"</font></td>"
        retVal += "<td align='right'><font size='4'>"+window.vue.getInstance('vue').$commonUtil.numberWithCommas(item.requireAmt)+"</font></td>"
        retVal += "</tr>"
      }
    })
    retVal += "</table>"
  } else {
    retVal += "<table cellpadding='0' cellspacing='1'>"
    retVal += "<tr><td>"
    retVal += "<font size='5' face='맑은 고딕'><b>" + data.covrName + "</b></font>"
    retVal += "</td><tr>"
    retVal += "<tr><td align='left'>"
    retVal += "<font size='4'>제안 전 : </font>" + "<font size='4'face='맑은 고딕'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.covrBfAmt) + "</font>" + "<font size='4'> 만원 </font>" // 제안전 보장금액
    retVal += "</td><tr>"
    retVal += "<tr><td align='left'>"
    retVal += "<font size='4'>제안 후 : </font>" + "<font size='4'face='맑은 고딕'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.covrAfAmt) + "</font>" + "<font size='4'> 만원 </font>" // 제안후 보장금액
    retVal += "</td><tr>"
    retVal += "<tr><td align='left'>"
    retVal += "<font size='4'>표준모델 : </font>" + "<font size='4'face='맑은 고딕'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.requireAmt) + "</font>"  + "<font size='4'> 만원 </font>" // 표준모델
    retVal += "</td><tr>"
    retVal += "</table>"
  }
  // let retVal = "<table cellpadding='0' cellspacing='1'>" // jskim 주석처리
  return retVal
}

/******************************************************************************
  * TSSCT020M 연금계산기 : 설계후_전후비교
******************************************************************************/
fn_TSSCT020M_displayCompleteFunc = function(id) { // 적용예정
  setTimeout( function () {
    window.vue.getStore('progress').dispatch('UPDATE', false) // 차트 생성후 로딩바 지우기
  }, 500)  
}

fn_TSSCT020M_dataTip = function (seriesId, seriesName, index, xName, yName, data, values) {
  // debugger
  let retVal = "<table cellpadding='0' cellspacing='1'>"
  if ( data.prpsGb === 'bf' ) { // 설계구분 : 제안전 이면,
    let lv_pensBfDataList = []
    if ( seriesName == 'pAnut') {   // 개인
      lv_pensBfDataList = data.pensDataList.filter( x => { return (x.gubun === 'P') })
    } else if ( seriesName == 'gtpNpens') {   // 국민
      lv_pensBfDataList = data.pensDataList.filter( x => { return (x.gubun === 'N') })
    } else if ( seriesName == 'exptPpsn') {   // 퇴직
      lv_pensBfDataList = data.pensDataList.filter( x => { return (x.gubun === 'R') })
    }
    // console.log('lv_pensBfDataList :: ' + JSON.stringify(lv_pensBfDataList))

    for ( let i in lv_pensBfDataList )  {
      retVal += "<tr><td align='left'>"
      retVal += "<font size='2' color='#0000FF' face='맑은 고딕'><b>보유&nbsp</b></font>&nbsp"
      retVal += "<font size='2' face='맑은 고딕'>"+ (window.vue.getInstance('vue').$bizUtil.omitStr(lv_pensBfDataList[i].prdtNm,10)) + "</font>&nbsp"
      retVal += "<font size='2' face='맑은 고딕'>"+ window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.floor(lv_pensBfDataList[i].anpsn/10000)) + "</font>"
      retVal += "</td></tr>"
    }
  } else if (data.prpsGb === 'af' ) { // 설계구분 : 제안후 이면,
    let lv_pensAfDataList = []
    if ( seriesName == 'pAnut') {   // 개인
      lv_pensAfDataList = data.pensDataList.filter( x => { return (x.gubun === 'P') })
      let lv_anpsn_str1 = ''
      let lv_anpsn_str2 = ''
        for ( let i in lv_pensAfDataList )  {
          let lv_color = ( lv_pensAfDataList[i].prpsGb === 'BF' ) ? '#0000FF' : '#CC3300'
          let lv_prpsGb = ( lv_pensAfDataList[i].prpsGb === 'BF' ) ? '보유' : '추천'
          if (lv_pensAfDataList[i].chgAmt != undefined &&  lv_pensAfDataList[i].chgAmt === true ) { // 변액상품이면
            let lv_lowAmt = window.vue.getInstance('vue').$commonUtil.numberWithCommas(lv_pensAfDataList[i].anpsnAmt.lowAmt)
            let lv_midAmt = window.vue.getInstance('vue').$commonUtil.numberWithCommas(lv_pensAfDataList[i].anpsnAmt.midAmt)
            let lv_highAmt = window.vue.getInstance('vue').$commonUtil.numberWithCommas(lv_pensAfDataList[i].anpsnAmt.highAmt)
            let lv_lowPer = lv_pensAfDataList[i].anpsnPer.lowPer
            let lv_midPer = lv_pensAfDataList[i].anpsnPer.midPer
            let lv_highPer = lv_pensAfDataList[i].anpsnPer.highPer
            lv_anpsn_str1 =  lv_lowAmt +" ~ "+ lv_highAmt
            lv_anpsn_str2 =  lv_lowAmt +"("+lv_lowPer+"%)&nbsp;"+ lv_midAmt +"("+lv_midPer+"%)&nbsp;"+"   "+ lv_highAmt +"("+lv_highPer+"%)&nbsp;"
            retVal += "<tr><td align='left'>"
            retVal += "<font size='2' color='"+lv_color+"' face='맑은 고딕'><b>"+lv_prpsGb+"&nbsp</b></font>&nbsp;"
            retVal += "<font size='2' face='맑은 고딕'>"+ (window.vue.getInstance('vue').$bizUtil.omitStr(lv_pensAfDataList[i].prdtNm,10)) +"</font>&nbsp;"
            retVal += "<font size='2' face='맑은 고딕'>"+ lv_anpsn_str1 + "</font>"
            retVal += "</td></tr>"
            retVal += "<tr><td align='left'>"
            retVal += "<font size='2' face='맑은 고딕'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"+ lv_anpsn_str2 + "</font>"
            retVal += "</td></tr>"

        } else {
          retVal += "<tr><td align='left'>"
          retVal += "<font size='2' color='"+lv_color+"' face='맑은 고딕'><b>"+lv_prpsGb+"&nbsp;</b></font>"
          retVal += "<font size='2' face='맑은 고딕'>"+ (window.vue.getInstance('vue').$bizUtil.omitStr(lv_pensAfDataList[i].prdtNm,10)) + " "+"</font>&nbsp;"
          if ( lv_prpsGb == '보유' ) { // 보유상품은 만단위 절삭 처리 (국민 퇴직 연금과 같은 방식으로 처리)
            retVal += "<font size='2' face='맑은 고딕'>"+ window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.floor(lv_pensAfDataList[i].anpsn/10000)) + "</font>"
          } else { // 추천상품은 (변액상품이 있어서) 미리 만단위 절삭 했기 때문에 그대로 뿌려줌
            retVal += "<font size='2' face='맑은 고딕'>"+ window.vue.getInstance('vue').$commonUtil.numberWithCommas(lv_pensAfDataList[i].anpsn) + "</font>"
          }
          retVal += "</td></tr>"
        }
      }
    } else {
      if ( seriesName == 'gtpNpens') {   // 국민
        lv_pensAfDataList = data.pensDataList.filter( x => { return (x.gubun === 'N') })
      } else if ( seriesName == 'exptPpsn') {   // 퇴직
        lv_pensAfDataList = data.pensDataList.filter( x => { return (x.gubun === 'R') })
      }
      for ( let i in lv_pensAfDataList )  {
        retVal += "<tr><td align='left'>"
        retVal += "<font size='2' color='#0000FF' face='맑은 고딕'><b>보유&nbsp;</b></font>"
        retVal += "<font size='2' face='맑은 고딕'>"+ (window.vue.getInstance('vue').$bizUtil.omitStr(lv_pensAfDataList[i].prdtNm,10)) +"</font>&nbsp;"
        retVal += "<font size='2' face='맑은 고딕'>"+ window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.floor(lv_pensAfDataList[i].anpsn/10000)) + "</font>"
        retVal += "</td></tr>"
      }
    }
  }
  retVal += "</table>"
  // console.log('retVal dataTip ::: ' +retVal)
  return retVal
}

fn_TSSCT020M_InsideLabelFunc = function (seriesId, index, data, values) {
  console.log('data.pAnutLabel ::: ' + data.pAnutLabel)
  if ( seriesId === 'pAnut') { // 개인연금일때,
    if ( data.pAnutLabel === 0 || data.pAnutLabel === '0' ) { // 금액이 0이면 표시하지 않음
      retVal = ''
    } else {
      if ( data.prpsGb === 'bf' ) { // 설계전
        retVal = "<font size='19'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.pAnutLabel) + "</font>"
      } else { // 설계후
        retVal = "<font size='19'>" + data.pAnutLabel.replace('~', '<br><br>~<br><br>') + "</font>" // 변액상품이 있어서 미리 금액 천단위 표시
      }
    }
  } else if ( seriesId === 'gtpNpens') { // 국민연금일때,
    if ( data.gtpNpensLabel === 0 || data.gtpNpensLabel === '0' ) {
      retVal = ''
    } else {
      retVal = "<font size='19'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.gtpNpensLabel) + "</font>"
    }
  } else if ( seriesId === 'exptPpsn') { // 퇴직연금일때
    if ( data.exptPpsnLabel === 0 || data.exptPpsnLabel === '0' ) {
      retVal = ''
    } else {
      retVal = "<font size='19'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(data.exptPpsnLabel) + "</font>"
    }
  }
  
  // else { // 국민, 퇴직연금일때
    // if ( values[1] == 0 || values[1] === '0' ) {
    //   retVal = ''
    // } else {
    //   retVal = "<font size='19'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(values[1]) + "</font>"
    // }

  //   if ( values[1] == 0 || values[1] === '0' ) {
  //     retVal = ''
  //   } else {
  //     retVal = "<font size='19'>" + window.vue.getInstance('vue').$commonUtil.numberWithCommas(values[1]) + "</font>"
  //   }
  // }
  return retVal
}

/******************************************************************************
  * TSSCT010P 기간별보장금액(설계전)
******************************************************************************/
fn_TSSCT010P_dataTip = function (seriesId, seriesName, index, xName, yName, data, values) {
  // console.log('seriesId :: ', seriesId)
  // console.log('seriesName :: ', seriesName)
  // console.log('index :: ', index)
  // console.log('xName :: ', xName)
  // console.log('yName :: ', yName)
  // console.log('data :: ', data)
  // console.log('values :: ', values)
  let retVal = "<table cellpadding='0' cellspacing='1'>"
  for (let i=1; i<=data.prdtCnt; i++) {
    // console.log('aaa ', data['prdt'+i+'Nm'])
    if (seriesName === data['prdt'+i+'Nm'] && data['prdt'+i+'Amount'] > 0) {
      retVal += "<tr>"
      retVal += "<td><font size='5' color='#0000FF' face='맑은 고딕'><b>보유&nbsp</b></font>&nbsp</td>"
      retVal += "<td><font size='5' face='맑은 고딕'>"+ window.vue.getInstance('vue').$bizUtil.omitStr(data['prdt'+i+'Nm'],10) + "</font>&nbsp</td>"
      retVal += "<td><font size='5' face='맑은 고딕'>"+ window.vue.getInstance('vue').$commonUtil.numberWithCommas(data['prdt'+i+'Amount']) + "</font></td>"
      retVal += "</tr>"
      break
    }
  }
  retVal += "</table>"
  return retVal
}

fn_TSSCT010P_hAxisLabelFunc = function (id, text) {
  // console.log('id :: ', id)
  // console.log('text :: ', text)
  let chartData = document.getElementById("CT010P").getChartData(),
      rtnVal = text+'세'
  if (Number(text) === chartData[0].wlifeAge) rtnVal = '종신'
  return '<font size="30">' + rtnVal + '</font>'
}

fn_TSSCT010P_vAxisLabelFunc = function (id, text, data, val) {
  return '<font size="24">' + window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.round(text)) + '</font>'
}

/******************************************************************************
  * TSSCT011P 기간별보장금액(설계후)
******************************************************************************/
fn_TSSCT011P_dataTip = function (seriesId, seriesName, index, xName, yName, data, values) {
  // console.log('===============================')
  // console.log('seriesId :: ', seriesId)
  // console.log('seriesName :: ', seriesName)
  // console.log('index :: ', index)
  // console.log('xName :: ', xName)
  // console.log('yName :: ', yName)
  // console.log('data :: ', data)
  // console.log('values :: ', values)
  let retVal = "<table cellpadding='0' cellspacing='1'>"
  for (let i=1; i<=data.prdtCnt; i++) {
    // console.log(data['prdt'+i+'Nm'])
    if (seriesName === data['prdt'+i+'Nm'] && data['prdt'+i+'Amount'] > 0) {
      let prdtType = data['prdt'+i+'PrdtType'] // RET: 보유, RECM: 추천
      let prdtTypeNm = prdtType === 'RET' ? '보유' : '추천'
      let prdtTypeColor = prdtType === 'RET' ? '#0000FF' : '#ff9f00'
      retVal += "<tr>"
      retVal += "<td><font size='5' color='"+prdtTypeColor+"' face='맑은 고딕'><b>"+ prdtTypeNm +"&nbsp</b></font>&nbsp</td>"
      retVal += "<td><font size='5' face='맑은 고딕'>"+ window.vue.getInstance('vue').$bizUtil.omitStr(data['prdt'+i+'Nm'],10) + "</font>&nbsp</td>"
      retVal += "<td><font size='5' face='맑은 고딕'>"+ window.vue.getInstance('vue').$commonUtil.numberWithCommas(data['prdt'+i+'Amount']) + "</font></td>"
      retVal += "</tr>"
      break
    }
  }
  retVal += "</table>"
  return retVal
}

fn_TSSCT011P_hAxisLabelFunc = function (id, text) {
  // console.log('id :: ', id)
  // console.log('text :: ', text)
  let chartData = document.getElementById("CT011P").getChartData(),
      rtnVal = text+'세'
  if (Number(text) === chartData[0].wlifeAge) rtnVal = '종신'
  return '<font size="30">' + rtnVal + '</font>'
}

fn_TSSCT011P_vAxisLabelFunc = function (id, text, data, val) {
  return '<font size="24">' + window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.round(text)) + '</font>'
}

// 활동성과 그래프
fn_TSSAP101P_hAxis = function (id, value) {
  let retVal ='<font size="25"><b>' + value + '</b></font>'
  return retVal
}

fn_TSSAP101P_vAxis = function (id, value) {
  let retVal ='<font size="25"><b>' + window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.round(value)) + '</b></font>'
  return retVal
}

fn_TSSAP101P_series = function (seriesId, index, data, values) {
  return '<font size="25"><b>' + window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.round(values[1])) + '</b></font>'
}

fn_TSSAP101P_dataTip = function (seriesId, seriesName, index, xName, yName, data, values) {
  let retVal = "<table cellpadding='0' cellspacing='1'>"
      retVal += "<tr>"
      retVal += "<td><font size='5' face='맑은 고딕'>"+ values[0] + "</font>&nbsp&nbsp&nbsp</td>"
      retVal += "<td><font size='5' face='맑은 고딕'>"+ window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.round(values[1])) + "</font></td>"
      retVal += "</tr>"
  retVal += "</table>"
  return retVal
}

fn_TSSCM292P_LabelFunc = function (seriesId, index, data, values) {
  return `${window.vue.getInstance('vue').$commonUtil.numberWithCommas(Math.floor(values[1]))}만원`;
}
