// -----------------------------------------------------------------------------
// rMate Chart H5 License Key 
//
// Product Name : rMate Chart for HTML5 v5.0
// License Type : Enterprise License
// Customer : 삼성생명 (SAMSUNG LIFE INSURANCE)
// Product No : 1B1C-7C09-A93E-2FFK
// Authenticated server Info : the number of authenticated server = 8, server's IP = 121.253.31.91(OP), 121.253.31.92(OP), 121.253.31.116(OP),121.253.31.50(L4),121.253.33.176(QT), 100.252.201.133(DEV), 112.106.224.21(DR), 112.106.224.22(DR), 112.106.224.20(DR L4) 
//                                                                     server's Domain = tss.samsunglife.com(OP,DR), vtss.samsunglife.com(QT), wb1.t.tss.samsunglife.kr(DEV), mss.samsunglife.com(OP,DR), vmss.samsunglife.com(QT)
// Expiration date : unlimited
//
rMateChartH5License = "6e1bf94aae3b6fa28d086bfa3f41dc129673465ce13cbfc141be0eb785baaf92:6300300b39722c6b312e316532662e69316c3067366e2e753273326d34612e73322e31732c733174312e32742e2e3131306236772e2c3233323334312e2e323132302c32312e313232352e32312e303036302e31322c326d346f2e63322e30652c666d69736c73672e6e737561736d6d736175736e2e67736c7369746676652c2e3663376f316d2e2c3376336d2e733373352e32732e61316d327331752c6e6d676f6c63692e666565662e69636c6f676d6e207545733a6d2a6120734c2e3a7331734274312c43302d35372e43313033392e2d3341353932332e45312d323231462c46364b312031502e42313a33482e44334f3520324c2e56313a3232312e2c30322039502e56313a33432e35332d3545324e2e2d3135322e31302c203145394c2e3a316633202e43333a353232302e323133323031353a314836";
// -----------------------------------------------------------------------------
